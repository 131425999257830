<template>
  <div class="main-container">
    <MainMenu v-model="items" @logout="logoutBtn" />
    <div class="container-pages">
      <router-view />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MainMenu from "../components/main/menu/MainMenu.vue";
export default {
  components: { MainMenu },
  data: () => ({
    items: [
      {
        title: "Пользователи",
        isUrl: false,
        icon: "mdi-account-multiple",
        link: { name: "Users" },
        active: false,
        roles: ["admin"],
      },
      {
        title: "Группы",
        isUrl: false,
        icon: "mdi-folder-account",
        link: { name: "Groups" },
        active: false,
        roles: ["admin"],
      },
      {
        title: "Теги",
        isUrl: false,
        icon: "mdi-label",
        link: { name: "Tags" },
        active: false,
        roles: ["admin"],
      },
      // {
      //   title: "Авторы",
      //   isUrl: false,
      //   icon: "mdi-account-star",
      //   link: { name: "authors" },
      //   active: false,
      //   roles: ["admin"],
      // },

      {
        title: "Курсы",
        isUrl: false,
        icon: "mdi-library",
        link: { name: "Courses" },
        active: false,
        roles: ["admin"],
      },
      {
        title: "Топики",
        isUrl: false,
        icon: "mdi-tag-multiple",
        link: { name: "Topics" },
        active: false,
        roles: ["admin"],
      },
      {
        title: "Новости",
        isUrl: false,
        icon: "mdi-newspaper",
        link: { name: "NewsArticles" },
        active: false,
        roles: ["admin"],
      },
      // {
      //   title: "Видео",
      //   isUrl: false,
      //   icon: "mdi-video",
      //   link: { name: "video" },
      //   active: false,
      //   roles: ["admin"],
      // },

      // {
      //     title: 'Транзакции',
      //     isUrl: false,
      //     icon: 'mdi-bank',
      //     link: {name: 'transactions'},
      //     roles: ['admin']
      // },
      // {
      //   title: "Push-уведомления",
      //   isUrl: false,
      //   icon: "mdi-message",
      //   link: { name: "push" },
      //   active: false,
      //   roles: ["admin"],
      // },
      // {
      //     title: 'Покупки',
      //     isUrl: false,
      //     icon: 'mdi-bookshelf',
      //     link: {name: 'directories'},
      //     roles: ['admin']
      // },
      {
        title: "Документы",
        isUrl: false,
        icon: "mdi-file-document-edit",
        link: { name: "documents" },
        active: false,
        roles: ["admin"],
      },
      // {
      //   title: "Шаблоны - Email",
      //   isUrl: false,
      //   icon: "mdi-clipboard-text",
      //   link: { name: "mailTemplates" },
      //   active: false,
      //   roles: ["admin"],
      // },
      {
        title: "Настройки",
        isUrl: false,
        icon: "mdi-settings-outline",
        link: { name: "settingMobile" },
        active: false,
        roles: ["admin"],
      },
      // {
      //     title: 'Модерация',
      //     isUrl: false,
      //     icon: 'mdi-check-decagram',
      //     link: {name: 'moderation'},
      //     roles: ['admin'],
      //     check: true
      // },
      // {
      //   title: "История версий",
      //   isUrl: false,
      //   icon: "mdi-calendar-clock",
      //   link: { name: "versions" },
      //   active: false,
      //   roles: ["admin"],
      // },
      {
        title: "Тестирование",
        isUrl: false,
        icon: "mdi-ab-testing",
        link: { name: "Tests" },
        active: false,
        roles: ["admin"],
      },
      {
        title: "Баннеры",
        isUrl: false,
        icon: "mdi-card-bulleted-outline",
        link: { name: "banners" },
        active: false,
        roles: ["admin"],
      },
      {
        title: "Модерация",
        isUrl: false,
        icon: "mdi-account-multiple-check-outline",
        link: { name: "moderation" },
        active: false,
        roles: ["admin"],
      },
    ],
  }),
  created() {},

  methods: {
    logoutBtn() {
      this.$cookies.remove(this.$store.state.adminPanelKeyCookie);
      axios
        .get(this.$store.getters.apiAdminPanelV4 + "/logout", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then(() => {
          this.$router.go(0);
        })
        .catch(() => {
          this.$router.go(0);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: auto;

  .container-pages {
    width: 100%;
    height: 100vh;

    min-width: 892px;
    background: var(--app-grey-1);
  }
}
</style>
