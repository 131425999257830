<template>
  <div class="menu-container" :style="`width: ${resizeValue}px !important`">
    <div class="menu-header" :style="`width: ${resizeValue}px !important`">
    
      <div class="header-icon-void">
        <v-icon size="18" color="var(--app-white)">mdi-account</v-icon>
      </div>
       
      <div v-if="!drawer" :style="`max-width:${resizeValue - 82}px`" class="header-name">Администратор</div>
       
      <transition name="show">
        <v-icon @click="$emit('logout')" v-if="!drawer" size="18" olor="var(--app-grey-3)">mdi-logout-variant</v-icon>
      </transition>
    </div>
    <div class="menu-items" :style="`width: ${resizeValue}px !important`">
      <MainMenuItem
        v-for="(item, index) in value"
        :key="index"
        :item="item"
        :compact="drawer"
        :resizeValue="resizeValue"
      />
    </div>
    <div class="menu-bottom" @click="clickListButton" :style="`width: ${resizeValue}px !important`">
      <img src="@/assets/icon/menu.svg/" />
      <div v-if="!drawer" class="menu-bottom-text" >Свернуть меню</div>
    </div>
  </div>
</template>
<script>
import MainMenuItem from "@/components/main/menu/MainMenuItem";
export default {
  components: {
    MainMenuItem,
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    drawer: false,
    resizeValue: 180,
  }),
  created() {
    this.checkRoute();
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  methods: {
    clickListButton() {
      this.drawer = !this.drawer;
      this.drawer ? (this.resizeValue = 48) : (this.resizeValue = 180);
    },
    updateList() {
      this.$emit("input", this.value);
    },
    checkRoute() {
      for (let index = 0; index < this.value.length; index++) {
        const element = this.value[index];
        if (this.$route.name == element.link.name) {
          element.active = true;
        } else {
          if (this.$route.matched && this.$route.matched.length > 0) {
            const coincidence = this.$route.matched.filter((i) => {
              if (element.link.name == i.name) {
                return i;
              }
            });
            if (coincidence[0]) {
              element.active = true;
            } else {
              let metaName;
              if (this.$route.meta && this.$route.meta.parent) {
                metaName = this.$route.meta.parent.filter((i) => {
                  if (element.link.name == i) {
                    return i;
                  }
                });
              }

              if (metaName && metaName[0]) {
                element.active = true;
              } else {
                element.active = false;
              }
            }
          }
        }
      }
      this.updateList();
    },
  },
};
</script>
<style lang="scss">
.menu-container {
  height: 100%;
  //   min-height: 600px;
  background: var(--app-grey-2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
      user-select: none;

  .menu-header{
    padding: 24px 12px 16px 12px;
    display: flex;
    align-items: center;
    .header-icon-void{
      min-width: 24px;
      min-height: 24px;
      border-radius: 50%;
      background: var(--app-grey-3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-name{
      margin: 0px 8px;
      font-size: 12px;
      color: var(--app-grey-3);
      overflow: hidden;
      word-break: break-word;
    }
    i{
      cursor: pointer;
    }
  }
  .menu-bottom {
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: var(--app-grey-2);

    .menu-bottom-text{
      color: var(--app-grey-3);
      font-size: 12px;
      margin-left: 12px;
      font-weight: 500;
    }
  }
  .menu-items {
    height: 100%;
    overflow-y: auto;
    transition: 0.3s;
  }
}
.show-enter-active{
 opacity: 0;
}
.show-leave-active{
 display: none !important;
 
}
</style>