<template>
  <div v-if="!item.children" @click="$emit('click', item)">
    <router-link
      :class="[listItemClass, item.active ? 'list-active' : '']"
      :to="item.link ? item.link : ''"
      :style="`width: ${resizeValue}px;`"
    >
      <div class="list-line" v-if="item.active"></div>
      <div
        v-if="iconHide"
        :class="listItemIconClass"
        :style="`width:${iconSize}px`"
      ></div>
      <v-icon
        v-else
        :size="iconSize"
        :class="listItemIconClass"
        color="var(--app-grey-3)"
      >
        {{ item.icon }}
      </v-icon>
      <div v-if="!compact" :class="listItemTextClass">
        {{ item.title }}
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    item: { type: Object },
    resizeValue: { type: Number },
    compact: {
      type: Boolean,
      default() {
        return false;
      },
    },
    menu: {
      type: Boolean,
      default() {
        return false;
      },
    },
    iconHide: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    listItemClass: "list-item",
    listItemIconClass: "list-item-icon",
    listItemTextClass: "list-item-text",
    iconSize: "22",
  }),
  methods: {
    ckickItem(item) {
      this.$emit("ckickItem", item);
    },
  },
  mounted() {},
  created() {
    // this.checkType();
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.list-animate-enter-active {
  opacity: 0;
  transform: translateY(30px);
}
.list-animate-enter-to {
  transition: all 0.4s ease;
  opacity: 1;
  transform: translateY(0);
}
.list-animate-leave-active {
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(10px);
}

.list-item {
  display: flex;
  align-items: center;
  padding: 12px 8px 12px 12px;
  min-height: 45px;
  user-select: none;
  cursor: pointer;
  position: relative;
  .list-item-icon {
    margin-right: 8px;
  }
  .list-item-text {
    font-size: 12px;
    color: var(--app-grey-3);
  }
  .list-line {
    width: 3px;
    height: 100%;
    background: var(--app-blue);
    position: absolute;
    left: 0;
    top: 0;
    
  }
}
.list-item:hover {
  .list-item-text {
    color: var(--app-blue);
  }
  i {
    color: var(--app-blue) !important;
    transition: 0s;
  }
}

.list-active {
  width: 100%;
  background: var(--app-separators);
  .list-item-text {
    color: var(--app-blue);
    font-weight: 500;
  }
  i {
    color: var(--app-blue) !important;
    transition: 0s;
  }
}
</style>
